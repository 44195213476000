type AbtastyCallback = (abtasty: any) => void;

const withAbtasty = (callback: AbtastyCallback) => {
  // See: https://developers.abtasty.com/docs/tag/tag-javascript-events#wait-to-send-transaction
  if (
    typeof window.ABTasty !== 'undefined' &&
    typeof window.ABTasty.eventState !== 'undefined' &&
    !!window.ABTasty.eventState.trackingInitialized &&
    window.ABTasty.eventState.trackingInitialized.status === 'complete'
  ) {
    callback(window.abtasty);
  } else {
    window.addEventListener('abtasty_trackingInitialized', () => {
      callback(window.abtasty);
    });
  }
};

export default withAbtasty;

import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { removeCookieFromCurrentDomainAndAncestors } from '@libs/cookies/removeCookieFromCurrentDomainAndAncestors';
import { nonEmptyStringOrNull } from '@libs/stringUtils';
import withAbtasty from '@libs/withAbtasty';
import { CookiesConstants } from '../../../generated/phpConstants';

// See: https://app2.abtasty.com/library/tracking/indicators?indicatorType=customTracking
const ABTASTY_CUSTOM_TRACKING_LOGIN_SUCCESS_NAME = 'user_login_success';

interface Props {
  children: any;
}

const WithLoginSuccessAbtastyTrackingEvent = ({ children }: Props) => {
  const [cookies] = useCookies([CookiesConstants.LOGIN_SUCCESS_FOR_ABTASTY]);
  const loginSuccessForAbtasty = nonEmptyStringOrNull(cookies[CookiesConstants.LOGIN_SUCCESS_FOR_ABTASTY]);

  useEffect(() => {
    if (loginSuccessForAbtasty === '1') {
      withAbtasty(() => {
        window.ABTastyClickTracking(ABTASTY_CUSTOM_TRACKING_LOGIN_SUCCESS_NAME);
        removeCookieFromCurrentDomainAndAncestors(CookiesConstants.LOGIN_SUCCESS_FOR_ABTASTY);
      });
    }
  }, [loginSuccessForAbtasty]);

  return children;
};

export default WithLoginSuccessAbtastyTrackingEvent;

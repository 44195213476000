import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { UserConfig, appWithTranslation, useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import AppLoadingBar from '@components/utilities/AppLoadingBar/AppLoadingBar';
import WithLoginSuccessAbtastyTrackingEvent from '@components/utilities/WithLoginSuccessAbtastyTrackingEvent/WithLoginSuccessAbtastyTrackingEvent';
import { initLmcCookiesConsentManager } from '@libs/cookies/initLmcCookiesConsentManager';
import { pushVirtualPageview } from '@libs/ga/pushVirtualPageview';
import CookieConsentCategoriesProvider from '@providers/CookieConsentCategoriesProvider';
import ModalProvider from '@providers/ModalProvider';
import { useApollo } from 'apollo-client';
import '../styles/index.scss';
import { getGlobalServerSideProps } from '../helpers/globalServerSide';
import { RequestContext } from '../helpers/requestContext';
import nextI18NextConfig from '../next-i18next.config';

type PageProps = Awaited<ReturnType<typeof getGlobalServerSideProps>> & { initialApolloState: any };

const App = ({ Component, pageProps, router }: AppProps) => {
  const pagePropsWithTypes: PageProps = pageProps;

  const { apolloClient, useApolloNetworkStatus } = useApollo(pagePropsWithTypes.initialApolloState);
  const { i18n } = useTranslation();

  useEffect(() => {
    // Note: Error pages (404, 500 etc.) cannot pass cookieNamesByCategoryForCurrentUser from server-side because
    // they are rendered statically. Since every user can have different categorization of cookies,
    // we cannot initialize the consent manager on such pages.
    if (typeof window !== 'undefined' && pagePropsWithTypes.cookieNamesByCategoryForCurrentUser != null) {
      initLmcCookiesConsentManager(i18n.language, pagePropsWithTypes.cookieNamesByCategoryForCurrentUser);
    }
  }, [pagePropsWithTypes.cookieNamesByCategoryForCurrentUser]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pushVirtualPageview(document.title, url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head key="robots">
        <meta key="robots" name="robots" content="index,follow" />
        <meta key="googlebot" name="googlebot" content="index,follow" />
      </Head>
      <AppLoadingBar useApolloNetworkStatus={useApolloNetworkStatus} />
      <ApolloProvider client={apolloClient}>
        <RequestContext.Provider value={pagePropsWithTypes.requestContextData}>
          <CookiesProvider>
            <CookieConsentCategoriesProvider.Provider value={pagePropsWithTypes.cookieNamesByCategoryForCurrentUser}>
              <WithLoginSuccessAbtastyTrackingEvent>
                <ModalProvider>
                  <Component {...pageProps} />
                </ModalProvider>
              </WithLoginSuccessAbtastyTrackingEvent>
            </CookieConsentCategoriesProvider.Provider>
          </CookiesProvider>
        </RequestContext.Provider>
      </ApolloProvider>
    </>
  );
};

export default appWithTranslation(App, nextI18NextConfig as UserConfig);
